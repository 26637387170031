var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:""},[_c('titleBar',{attrs:{"title":" ","back":false,"showBackArrowDesktop":true,"subtitle":"","centered":false,"theme":"white","showSignup":false,"containerClass":"donotuse","showDesktop":true,"showAfterScroll":0,"scrollTopOnClick":true,"inline":false,"actionButtonStyle":"small","actionButtonRoundBg":"transparent","iconPack":"far","isHome":false,"actions":[
      {
        icon: 'search',
        to: '/explore',
        hidden: false,
        hiddenTablet: false,
      },
      {
        icon: 'bell',
        to: '/notifications',
        hidden: false,
        hiddenTablet: false,
      },
      /*  {
        icon: 'comment',
        to: '/messages',
        hidden: false,
        hiddenTablet: false,
      },*/
    ]}}),_vm._v(" "),_c('div',{staticClass:"section"},[_c('exploreTabs',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.query.ref == 'tabs'),expression:"$route.query.ref == 'tabs'"}]})],1),_vm._v(" "),_c('br'),_vm._v(" "),_c('br'),_vm._v(" "),_c('br'),_vm._v(" "),_c('div',{staticClass:"section"},[_c('div',{staticClass:"container",staticStyle:{"max-width":"1000px"}},[_c('div',{staticClass:"columns",staticStyle:{"align-items":"center"}},[_c('div',{staticClass:"column"},[_c('vue-typed-js',{staticStyle:{"margin-bottom":"30px"},attrs:{"strings":_vm.botsFor}},[_c('h1',{staticClass:"title is-1"},[_vm._v("Bots for "),_c('span',{staticClass:"typing has-text-primary"})])]),_vm._v(" "),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.searchBots.apply(null, arguments)}}},[_c('b-field',[_c('b-input',{attrs:{"placeholder":"Find bots by name, description, etc...","icon":"fas fa-search","expanded":"","rounded":"","size":"is-large"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.searchBots.apply(null, arguments)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_vm._v(" "),_vm._m(0)]),_vm._v(" "),_c('div',{staticClass:"cat-buttons",staticStyle:{"margin-bottom":"45px"}},_vm._l((_vm.cats),function(cat){return _c('router-link',{key:cat.id,staticClass:"button is-primary is-light",attrs:{"to":`/${cat.value}`},on:{"click":function($event){return _vm.searchBots(cat.value)}}},[_c('span',{staticClass:"icon is-small"},[_c('i',{class:'icon far fa-' + cat.icon})]),_vm._v(" "),_c('span',[_vm._v(" "+_vm._s(cat.label))])])}),1)])]),_vm._v(" "),_c('FeaturedBots',{attrs:{"blocks":_vm.blocks}}),_vm._v(" "),_c('div',{staticClass:"section"},[(!_vm.loadingProj)?_c('div',{staticClass:"container home"}):_vm._e()])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column is-4 is-hidden-mobile"},[_c('img',{staticStyle:{"max-height":"200px","margin":"auto","display":"block","mix-blend-mode":"multiply"},attrs:{"src":"https://cdn1.onlybots.cc/bots/OnlyBots.assets/posts/UwZ49jvFKL/OvCV-sfLi0___felix_m___3D_plastilina_ai_chatbot_dissasembled_on_clean_white__fb1453d7-ce3d-4abe-9f52-645799c8ff19.png?width=300&height=300&aspect_ratio=100:101"}})])
}]

export { render, staticRenderFns }